.uploader-container {
  width: 100px;
}

.preview {
  object-fit: cover;
  border-radius: 50px;
  margin-bottom: 3px;
}

.s3progress-container {
  display: flex;
  margin-bottom: 2px;
}

.s3progress-bar {
  min-width: 50px;
}

.collapsed {
  display: none !important;
}

.full-width {
  width: 100%;
  border: 1px solid red;
}

.s3progress-container i {
  text-align: center;
  color: green;
  margin-left: 5px;
}

.concealed {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.concealed:focus + label,
.concealed + label:hover {
  background-color: red;
}

.concealed + label {
  cursor: pointer; /* "hand" cursor */
}

//noinspection CssOverwrittenProperties
.concealed:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}