.off-canvas-content{
  height: 100vh;
  overflow: auto;
}

.container{
  height: calc(100% - 50px);
  overflow-y: scroll;
  @include breakpoint(medium){
    height: calc(100% - 80px);
  }
  
}
.container-2{
  height: calc(100% - 50px);
  overflow-y: auto;
  @include breakpoint(medium){
    height: calc(100% - 50px);
  }
  background-color: $color-bg-blue;
}



.bg-bg{
 background-color: $color-bg-blue;
}