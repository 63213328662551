.merri {
  font-family: $merri;
}

.hero-section {
  position: relative;
  height: 120vh;
  background-image: linear-gradient(to bottom,
          #4433cc, #3b36af, #373791, #353673, #333355);
  @include breakpoint(medium) {
  height: calc(100vh - 50px);
    background-image: linear-gradient(to bottom right,
            #4433cc, #3b36af, #373791, #353673, #333355);


  }

  &_slide-1 {
    opacity: 0;
    @include breakpoint(medium){
      opacity: 1;
    overflow: hidden;
    position: absolute;
    right: 0;
    height: 100%;
    text-align: right;
    }

    img {
      max-height: 100%;
      //position: relative;
      //right: -100%;


    }

  }


  &_slide-2 {
    opacity: 0;
    @include breakpoint(medium){
      opacity: 1;
    overflow: hidden;
    position: absolute;
    right: 0;
    height: 100%;
    text-align: right;
    }

    img {
      max-height: 100%;

    }
  }

  &_content{
    @include breakpoint(medium){
      margin-top: 7rem;

    }
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

  }


  color: $white;


  //.grid-x {
  //  @include breakpoint(medium) {
  //    height: calc(100vh - 50px);
  //  }
  //}

  h1 {
    display: none;
    @include breakpoint(medium) {
      display: block;
      margin-top: 3rem;
    }
  }

  .subtitle {
    font-size: 1.5rem;
    font-weight: bold;
    //text-align: center;
    margin-bottom: 1.5rem;
    //margin-top: 30px;
  }

  p {
    font-size: 1.1rem;
  }

  &_fly-in {
    position: absolute;
    right: 0;
    top: 0;
    height: calc(100vh - 50px);
    overflow: hidden;
    //max-width: 40%;

    img {
      display: block;
      right: 0;
      top: 0;

    }

    #project {

    }
  }

}

.app-image {
  display: none;

  @include breakpoint(medium) {
    display: block;
    text-align: right;

  }
  @include breakpoint(large) {
    display: block;
    position: absolute;
    right: 0;
    bottom: 10%;
    margin: 0;
  }
}

.mobile-app-image{
  margin-bottom: -15rem;
}

.button-container {

  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  @include breakpoint(medium) {
    flex-direction: row;
    justify-content: center;
    //margin-top: 1rem;
  }
}

.path-button {

  @include breakpoint(medium) {
    width: 400px;
    padding: 1.8rem 0;
    font-size: 1.2rem;
  }
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 1rem;

  //text-align: center;
  //border-radius: .5rem;
  //padding: 1rem 0;
  //font-size: 1.1rem;
  margin-bottom: .8rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .3);
  //text-transform: uppercase;
  //margin-left: 1rem;
  //margin-right: 1rem;
  //letter-spacing: 1px;


  &.left {
    background-color: #e5405c;
    border-radius: 1rem;
    @include breakpoint(medium) {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &.right {
    background-color: #f0ad46;
    border-radius: 1rem;
    @include breakpoint(medium) {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }

}


.info-one {
  background-color: #333355;
  color: $white;

  &_text {
    max-width: 500px;
    margin: 0 auto;
    padding: 5rem 2rem;
    @include breakpoint(medium) {
      padding: 10rem 0;
    }

    h2 {
      font-size: 2rem;
      margin-bottom: 1.5rem;
      color: $color-orange;
      font-weight: bold;
    }
  }

  .bg-people {
    background-image: url("/images/landing/team.png");
    background-size: cover;
  }

}

.info-two {
  background-color: #f2f5f9;
  position: relative;
  @include breakpoint(medium) {
    margin-bottom: -60px;
  }

  &_img {
    @include breakpoint(medium) {
      margin-top: 8rem;
      margin-bottom: -60px;
    }
    //z-index: 1000;
  }

  &_text {
    @include breakpoint(medium) {
      box-shadow: 2px 10px 12px rgba(0, 0, 0, .3);
      position: absolute;
      bottom: -70px;
      max-width: 600px;
      border-radius: 2rem;
    }
    padding: 3rem;
    background-color: $white;

    h2 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .pink {
      color: $color-pink;
      font-weight: bold;
      margin-bottom: 1rem;
      font-size: 1.1rem;
    }

  }

}

.info-three {
  background-color: $color-pink;
  padding: 5rem 0;
  @include breakpoint(medium) {
    padding-top: 300px;
    padding-bottom: 200px;
  }
  color: $white;

  .icon {
    img {
      width: 150px;
      height: 130px;
    }

    margin-bottom: 1rem;
  }
}

.info-four {
  background-color: $color-purple;
  color: $white;
  position: relative;
  padding: 5rem 0;
  @include breakpoint(medium) {
    padding-top: 10rem;
    padding-bottom: 12rem;
  }

  h2 {
    font-weight: bold;
  }

  h5 {
    color: $color-orange;
    margin-bottom: 1rem;
  }


    .move-up{
      transform: translateY(-15px);
      transition: all .2s;
    }

  .stack-images {


    img {
      display: block;
    }

    &_3 {
      display: none;
      @include breakpoint(medium) {
        display: block;
        position: absolute;
        z-index: 100;
        right: 0;
        top: -50px;
        transition: all .2s;
      }
    }



    &_2 {
      display: none;
      @include breakpoint(medium) {
        display: block;
        position: absolute;
        z-index: 99;
        right: 0;
        top: -50px;
        transition: all .2s;
      }
    }

    &_1 {
      display: none;
      @include breakpoint(medium) {
        display: block;
        position: absolute;
        z-index: 98;
        right: 0;
        top: -50px;
        transition: all .2s;
      }
    }
  }
}

.info-five {
  margin-bottom: 2rem;
  @include breakpoint(medium) {
    margin-top: 20rem;
    margin-bottom: -150px;
  }

  &_text {
    padding: 3rem;
    background-color: $white;
    @include breakpoint(medium) {
      box-shadow: 0px 5px 12px rgba(0, 0, 0, .4);
      position: absolute;
      max-width: 500px;
      border-radius: 2rem;
      right: 0;
      bottom: 100px;
    }

    h2 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .pink {
      color: $color-pink;
      font-weight: bold;
      margin-bottom: 1rem;
      font-size: 1.1rem;
    }
  }
}

.info-six {
  background-color: $color-orange;
  @include breakpoint(medium) {
    padding-top: 11rem;
    padding-bottom: 10rem;
  }
  padding: 5rem 0;
  color: $white;

  .icon {
    img {
      width: 150px;
      height: 130px;
    }

    margin-bottom: 1rem;
  }
}

.contact {
  background-color: #f2f5f9;
  padding: 3rem 0;

  .lighter-text {
    font-size: .9rem;
    color: $dark-gray;
  }

}

.landing-footer {
  background-color: $color-purple;
  color: $white;
  padding: .5rem;

}