#offCanvasRight{
  width: 100%;
  background-color: white;
  @include breakpoint(500px){
    width: 300px;
  }

  .close-button{
    color: white;
    z-index: 100;
  }

 .main-image{
   position: absolute;
   display: block;
   width: 50px;
   height: 50px;
   bottom: -25px;
   left: 50%;
   transform: translateX(-50%);
   border-radius: 50%;
   background-color: $light-gray;
   border: solid 1px $color-pink;
 }
}

.details-wrapper{
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  overflow-y: auto;
  
}

.main-details{
  
  padding-top: 2rem;
  padding-bottom: 1rem;
  text-align: center;
  &_title{
    font-size: .7rem;
  }
  &_name{
    font-size: 1.3rem;
  }
  &_code{
    text-transform: uppercase;
    font-size: .8rem
  }
}

.list-of-details{
  flex-grow: 2;
  padding: 1rem;
  margin-bottom: 1rem;

  ul{
    list-style: none;
    margin: none;

    li{
      display: flex;
      margin-bottom: .5rem;
    }
  }
  .detail-icon{
    margin-right: .5rem;
  }
  .detail-info{
    flex: 2;
    &_label{
      font-size: .9rem;
      color: $dark-gray;
    }
  }

  .detail-link{
    display: block;
    text-align: center;
    margin: .5rem 0;
  }
}

.authorize-container{
  background-color: lighten($primary-color, 40);
  text-align: center;
  // align-self: flex-end;
  // position: relative;
  // bottom: 0;
  // width: 100%;
  .button{
    padding: .5rem 2.5rem;
    margin-top: 1rem;
  }
}