.fix-size-button {
  width: 218px;
}

.button.disabled {
  background-color: darken($medium-gray, $amount: 20);
  color: $medium-gray;
  &:hover {
    background-color: darken($medium-gray, $amount: 10);
  }
}

.big-button{
  // background-color: $color-blue;
  // padding: .6rem 3rem;
  // box-shadow: 0px 2px 5px rgba(0,0,0,.5);
  // color: $white;
  // border-radius: 1rem;
  // transition: all .2s;
  // &:hover{
  //   box-shadow: 0 5px 10px rgba(0,0,0,.5);
  //   transition: all .2s;
  // }
  padding: .5rem 3rem;
}

.long-button{
padding: 0.85em 3em;
}


.button.no-margin {
  margin: 0 !important;
}