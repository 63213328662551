.login-dropdown{
  background-color: $white;
  position: absolute;
  width: 100%;
  border-top: 1px solid $light-gray;
  padding-bottom: 2rem;
  box-shadow: 0px 10px 10px rgba(0,0,0,.3);
  z-index: 1000;

  .blue-line{
    border-bottom: $color-blue 1px solid;
    margin-bottom: 1rem;
    padding: .5rem;

    &.active{
      border-bottom: $color-blue 4px solid;
    }

    &:hover{
      cursor: pointer;
    }
  }
}

.login-error-bar{
  position: absolute;
  //text-align: center;
  padding: .5rem;
  background-color: $white;
  top: 50px;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 2px solid $color-pink;
  border-top: 1px solid $light-gray;

}