.registration-header {
  // border-bottom: 1px solid $color-blue;
  text-align: center;
  background-color: $white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
  position: sticky;
  top: 0;
  z-index: 10;

  h3 {
    font-size: 1.3rem;
    padding: 1rem 0;
    border-bottom: 1px solid $medium-gray;
  }

  p {
    padding: .5rem 0;
    margin: 0;
    font-size: .7rem;
    @include breakpoint(medium) {
      font-size: .9rem;
    }
  }

  span, button {
    padding: 0 .2rem;
    @include breakpoint(medium) {
      padding: 0 1rem;
    }

    &.current {
      font-weight: bold;
    }
  }

  button {
    &:hover {
      cursor: pointer;
    }
  }
}


.image-upload {
  text-align: center;
  margin-bottom: 1rem;

  &_img {

    margin: 1rem;
    text-align: center;
    overflow: hidden;

    img {
      height: 70px;
      width: 70px;
      border-radius: 50%;
      background-color: $light-gray;
      border: solid 2px transparent;

      @include breakpoint(medium) {
        height: 100px;
        width: 100px;
      }
    }
  }

  .s3direct {
    margin: auto;
  }
}

// form wrapper sizes

.two {
  @include xy-cell(12);
  @include breakpoint(medium) {
    @include xy-cell(5);
  }

  .label-row {
    @include xy-cell(12);
    @include breakpoint(medium) {
      @include xy-cell(6);
    }
  }
}

.three {
  @include xy-cell(12);
  @include breakpoint(medium) {
    @include xy-cell(7);
  }

  .label-row {
    @include xy-cell(12);
    @include breakpoint(medium) {
      @include xy-cell(4);
    }
  }
}

.five {
  @include xy-cell(12);

  .label-row {
    @include xy-cell(12);
    @include breakpoint(medium) {
      @include xy-cell(20%);
    }
  }
}

.new-form-wrapper {
  position: relative;
  padding-top: 5px;
  @include xy-grid;
  margin-bottom: 2rem;
}


.dropzone-id {
  border: dotted 2px $color-purple;
  border-radius: 1rem;
  // padding: .5rem 1rem;

  margin: .5rem 2rem .5rem 0;
  padding: .5rem;
  @include breakpoint(medium) {
  }

  .fas {
    margin-right: 1rem;
  }

  &:hover {
    color: $white;
    background-color: lighten($color-purple, 20);
    border: dotted 2px $white;
    cursor: pointer;
  }


  &.submitted {
    border: none;
    background-color: $color-purple;
    color: white;
  }

  .fa-times {
    float: right;
    padding-top: .3rem;
  }

}

.id-upload {
  display: flex;

  &_side {
    flex: 1;
    margin: .5rem;

  }

  &_img {
    border: solid 1px $light-gray;
    height: 80px;
    margin-bottom: .5rem;
    text-align: center;


    img {
      visibility: hidden;
      max-height: 100%;
      max-width: 100%;
    }
  }
}


.new-user {
  @include xy-cell(12);

  &_btn {
    font-size: .8rem;
    color: $color-blue;
    padding: .3rem;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: $color-light-blue;
    }
  }
}

.form-submit-wrapper {
  @include xy-cell(12);
  text-align: center;
  margin-bottom: 2rem;

  button {
    padding: .5rem 4rem;
  }
}

.project-icon {
  height: 200px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

#new-currency {
  width: 150px;
}

.terms {
  display: none;
}

.id-name {
  font-size: .9rem;
  color: $dark-gray;
  margin: .3rem 0;
}