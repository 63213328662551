table {
  font-size: 12px;
}

thead th {
  font-weight: normal;
  // &:nth-child(1) {
  //   // width: 35px;
}

div.dataTables_wrapper div.dataTables_length select {
  height: calc(1.8125rem + 2px);
  // padding-top: 2px;
  // background-position: right -1rem bottom 0px;
  // line-height: 0;
  padding: 0 23px;
  border: solid 1px $light-gray;
  background-position: right -22px bottom 9px
}

div.dataTables_wrapper div.dataTables_filter input {
  height: calc(1.8125rem + 2px);
  border: solid 1px $light-gray;

}

.pagination li {
  border: solid 1px $light-gray;
  // display: block;
  margin: 0;

  &:first-child {
    border-radius: 5px 0 0 5px;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
  }
}

.pagination {
  display: flex;
}

table.dataTable td {
  // padding: .7rem .2rem;
}


table.dataTable tr {
  &:not(:last-child) {
    border-bottom: 1px solid $light-gray; // Applies to tr elements that are not the last child
  }

  &.selected {
    background-color: aliceblue; // Applies to tr elements that have the class selected
    font-weight: bold;
  }
}


table.dataTable {

  margin-top: 1.5rem !important;
  margin-bottom: .5rem !important;

}

.client-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  //font-size: 1rem;
  //border-bottom: 1px solid $light-gray;
  padding-bottom: 3px;
  margin-bottom: 3px;
}

.client-image {
  flex-shrink: 0;
  background-color: $light-gray;;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: .5rem;
}

.client-business {
  flex: 2;
  @include breakpoint(medium) {
    flex: 1;
  }

  h3 {
    border-bottom: 1px solid $light-gray;
    font-size: 1rem;
  }

  h4 {
    border-bottom: 1px solid $light-gray;
    font-size: 1rem;
  }

  h5 {
    font-size: .9rem;
    color: $medium-gray;
  }
}

.legal {
  font-size: .7rem;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 0;
  text-decoration: underline;
  color: $dark-gray;
  //border-bottom: solid 1px $light-gray;
  &_rep {
    //color: $dark-gray;
    font-size: .9rem;
    margin-bottom: 0;
  }
}

.details-btn {
  //width: 100%;
  @include breakpoint(600px down) {
    //  flex: 2;
    width: 100%;
  }
  text-align: right;
  //text-align: left;
  margin: 1rem;

  .button {
    margin-bottom: 0;
  }
}


.table-user-box {
  display: flex;
  align-items: center;

  margin-bottom: .2rem;
  // width: 100%;
  // flex-wrap: wrap;
  .client-image {
    // display: inline-block;
  }

  .client-name {

  }
}

.auth-date {
  font-size: .7rem;
  //padding-left: .2rem;
  // text-align: center; 
}

.table-status {
  padding: 0 1rem;
  border: solid 1px $medium-gray;
  background-color: $light-gray;
  border-radius: 1rem;
  text-align: center;
  font-size: .6rem;
  max-width: 150px;
  margin: 0 auto;

  &.active {
    border: solid 1px $success-color;
    background-color: lighten($success-color, 30);
  }

  &.pend {
    border: solid 1px $warning-color;
    background-color: lighten($warning-color, 30);

  }

  &.disabled {
    border: solid 1px $color-baby-blue;
    background-color: lighten($color-baby-blue, 30);

  }
}


table.dataTable > tbody > tr.child ul.dtr-details {
  display: block;
  @include breakpoint(medium) {
    display: inline-block;
    min-width: 200px;
  }

}


.error-text {
  color: red;
}

.project-item {
  display: flex;
  align-items: center;

  &_name {
    flex: 2;
    font-size: 1rem;
  }

}

#payableForm {
  display: flex;

}

.table-full-width {
  width: 100%;
}

.vendors-table {
  width: 100%;

  td.vendor-name {
    width: 40%;
  }

  td.vendor-skill-sets {
    width: 20%;
  }
}

.projects-table {
  width: 100%;

  td.project-name {
    width: 30%;
  }

  td.client-name {
    width: 20%;
  }
}