.start-panel{
  height: 50px;
  background-color: white;
  // padding: 15px 10px;
  position: relative;
  display: flex;
  align-items: center;
  &_logo{
    width: 100%;
    text-align: center;
    // margin-bottom: .4rem;
    // line-height: 0;
    img{
      height: 20px;
    }
  }
  &_user{
    display: flex;
    align-items: center;
    

    &_img{
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-size: cover;
      background-position: center center;
    }
    &_name{
      flex: 2;
      padding-left: .5rem;
      .user{
        margin: 0;
        font-weight: bold;
        font-size: .9rem;
        
      }

      .type{
        margin: 0;
        font-size: .8rem;
      }
    }

  }
  &_power{
    position: absolute;
    top: 5px;    
    right: .5rem;    
    border: none;
    width: 30px;
    height: 30px;
    // background-color: white !important;
    border-radius: 50%;
    transition: all .2s;
    
    &:hover{
      box-shadow: 1px 2px 3px rgba(0,0,0,.3);      
      transition: all .2s;
      cursor: pointer;
    }
  }

  &_info-edit{
    position: absolute;
    bottom: 15px;
    right: 10px;
    img{
      height: 15px;
    }
    opacity: .5;
    transition: all .2s;

    &:hover {
      opacity: 1;
      transition: all .2s;
      cursor: pointer;
    }
  }
}