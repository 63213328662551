.u-margin-top_extra-small {
  margin-top: .5rem;
}

.u-margin-top_small {
  margin-top: 1.5rem;
}

.u-margin-medium {
  margin-top: 2rem;
}

.u-margin-top_big {
  margin-top: 3rem;
}

.u-margin-bottom_extra-small {
  margin-bottom: .5rem;
}

.u-margin-bottom_small {
  margin-bottom: 1.5rem;
}

.u-margin-medium {
  margin-bottom: 2rem;
}

.u-margin-bottom_big {
  margin-bottom: 3rem;
}

.u-margin-top-bottom_tiny {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.u-margin-top-bottom_small {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.u-margin-top-bottom_medium {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.u-margin-top-bottom_big {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.u-margin-left_small {
  margin-left: 1.5rem;
}

.big-padding {
  @include breakpoint(medium) {
    padding: 2rem 4rem !important;
  }
}

// make pretty checkbox compatible

.pretty .state label {
  font-size: 16px;
  line-height: 16px;
}

.section-header {
  margin-left: .5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;


  &_icon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    flex-shrink: 0;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, .2);
    margin-right: .5rem;
    @include breakpoint(large) {
      height: 40px;
      width: 40px;
    }
    img{
      height: 100%;
      
    }
  }

  &_title {
    font-size: 1.3rem;
    color: $dark-gray;
    margin-right: 1rem;
    @include breakpoint(large) {
      font-size: 1.7rem;

    }
  }

  &_button {
    .button {
      margin-bottom: 0;

    }
  }
}

.right-border {
  @include breakpoint(medium) {
    border-right: 1px solid $light-gray;
  }
}

.flex-center {
  display: flex;
  // align-items: center;
}


hr {
  max-width: none;

  &.smaller {
    width: 75%;
    margin: 1.25rem 0;
  }
}

.blue-bg {
  background-color: $color-bg-blue;
}

// abide overrides

//.form-error {
//  //display: block;
//}

.val-error {
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: normal;
  color: #ee3355;
}


.no-list {
  list-style: none;
  margin: 0;
}

.errorlist{
  list-style: none;
  margin: 0;
  color: red;
  font-size: .75rem;
}