.position-left{
  display: flex;
  flex-direction: column;
  // justify-content: flex-start;

}

.new{
  // flex: 1;
  padding:.8rem .4rem .4rem 0.4rem;

  &_button{
    border: solid 2px $color-orange;
    border-radius: 2rem;
    padding: .4rem;
    color: $color-orange;
    transition: all .2s;

    span{
      margin-left: .5rem;
      
    }

    i{
      margin-left: 5px;

    }
    
    &:hover {
      cursor: pointer;
      background-color: darken($color-purple, 5);
      transition: all .2s;
    }
  }
}

.navigation {
  flex: 1;
  list-style: none;
  margin: 0;
  
  &_button{
    height: 45px;
    
    
    border-left: 5px solid transparent;
    // transition: all .2s;
    a{
      display: block;
      width: 100%;
      height: 100%;
      display: flex;
      color: white;
      align-items: center;
      }

    &_icon{
      width: 45px;
      overflow: hidden;
      padding: 3px;
      transition: all .2s;

      img{
        width: 40px;
        height: 40px;
      }
      .sidebar-collapsed &{
        // transition: all .2s;
        // margin-left: 6px;
      }
    }

    &_text{
      flex: 2;
      font-size: .8rem;
      margin-left: .3rem;      
    }

    &.active{
      border-left: 5px solid $color-pink;
      background-color: darken($color-purple, 5);
    }

    &:hover{
      transition: all .2s;
      background-color: darken($color-purple, 5);
      
    }
  }
}

.navigation-bottom{
  // position: absolute;
  // width: 100%;
  // bottom: 0;

  hr{
    margin: .3rem auto;
  }

  p{
    color: $white;
    margin-bottom: .1rem;
    margin-left: .5rem;
    font-size: .7rem;
    display: none;
    @include breakpoint(medium){
      display: block;
    }
  }
  .copy{
    color: $medium-gray;
    font-size: .7rem;
    font-weight: bold;
    margin-left: .5rem;
    margin-bottom: .3rem;
  }
}

