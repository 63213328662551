.login-bar {
  border-bottom: 1px solid $light-gray;
  position: relative;

  &_container {
    @include xy-grid-container;
    display: flex;
    justify-content: space-between;
    height: 50px;
  }

  &_desktop {
    @include breakpoint(small) {
      display: none;
    }
    display: block;
  }

  &_mobile {
    @include breakpoint(medium) {
      display: none;
    }
    display: block;
  }

  &_logo {
    padding-top: 10px;

    img {
      height: 25px;
      @include breakpoint(medium) {
        height: 30px;
      }
    }
  }

  &_icon {
    color: $dark-gray;
    padding-right: .5rem;
    font-size: 1.2rem;
    @include breakpoint(medium) {
      font-size: 1.5rem;
    }
  }

  &_login {
    display: flex;
    align-items: center;

    &_left {
      font-size: .8rem;
      @include breakpoint(medium) {
        font-size: 1rem;
        margin: 0 1rem;

        & .text{
          text-align: center;
        }
      }

      .links {
        display: flex;
        justify-content: space-around;
        color: $color-blue;



        #register-btn, #enter {
          border-radius: 5px;
          padding: 0 .6rem;
          //flex: 2;
          text-align: center;

          &:hover, &:active, &:focus {
            background-color: lighten($color-blue, 30);
            cursor: pointer;
          }

        }
      }
    }

    &_right {
      margin-left: .4rem;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      border: solid 1px $dark-gray;
      // padding-top: 8px;
      font-size: .8rem;
      transition: all .2s;

      &:hover, &:active, &:focus {
        cursor: pointer;
        background-color: $color-purple;
        color: white;
        transition: all .2s;
      }

    }
  }
}

.lang {
  width: 100px;
  padding: .3rem;

  &:hover {
    cursor: pointer;
  }

  &-sel {
    text-align: center;
    font-size: .8rem;

    &:hover, &:active, &:focus {
      background-color: lighten($color-blue, 30);
    }

    img {
      width: 15px;
      margin-right: .3rem;
    }

    &:not(:last-child) {
      margin-bottom: .3rem;
    }
  }
}

