.pending{
  @include xy-grid();
  margin-top: 1rem;
  margin-bottom: .8rem;

  &_item{
    @include xy-cell(12);
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    @include breakpoint(medium){
      @include xy-cell(6);
    }
    @include breakpoint(large){
      @include xy-cell(3);
    }

    &_logo{
      height: 40px;
      width: 40px;
      border: solid 1px $color-pink;
      border-radius: 50%;
      text-align: center;
      background-color: $color-purple;
      margin-right: .5rem;
      flex-shrink: 0;

      img{
        margin-top: 4px;
        height: 80%;
        width: 80%;
      }
    }

    &_text{
      flex: 2;
      &_title{
        color: $color-purple;
      }

      &_sub{
        font-size: .9rem;
        color: $dark-gray;
        font-weight: 300;
      }
    }
  }
}

// the panels

.stat-panels{
  @include xy-grid;
  // 4 panels per row on desktop
  .top{
    @include xy-cell(12);
    margin-bottom: 1rem;
    @include breakpoint(medium){
      @include xy-cell(6);
    }
    @include breakpoint(large){
      @include xy-cell(3);
    }
  }
  // 2 panels per row on desktop
  .middle{
    @include xy-cell(12);
    margin-bottom: 1rem;
    @include breakpoint(medium){
      @include xy-cell(6);
    }
  }
  // 3 panels per row on desktop
  .bottom{
    @include xy-cell(12);
    margin-bottom: 1rem;
    @include breakpoint(medium){
      @include xy-cell(4);
    }
  }
}

.panel{
  border-radius: 5px;
  transition: all .2s;
  text-align: center;
  position: relative;
  background-color: $white;
  
  &:active,&:focus,&:hover{
    box-shadow: 2px 3px 10px rgba(0,0,0, .2);
    transition: all .2s;
  }

  &_dots{
    position: absolute;
    top: 1rem;
    right: .7rem;
    font-size: 2rem;
    letter-spacing: 1px;
    color: $color-darker-gray;
    line-height: 0;
  }

  &_top{
    border: solid 1px $gray;
    border-bottom: 0;
    border-radius: 5px 5px 0 0;
    height: 180px;
  }

  &_bottom{
    border-radius: 0 0 5px 5px;
    border-top: solid 5px $color-darker-gray;
    height: 70px;
    background-color: $color-light-blue;
    position: relative;
  }

  &_stat{
    font-size: 2.5rem;
    color: $color-purple;
    margin-top: 2.5rem;

    &_title{
      font-size: .9rem;
      color: $dark-gray;
    }
  }

  &_icon{
    position: absolute;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: $color-purple;
    img{
      height: 80%;
      width: 80%;
      margin-top: 3px;
    }
  }

  &_text{
    margin-top: 1.2rem;
    font-size: .9rem;
    color: $color-purple;
  }  
}