.skill-pill{
  display: inline-block;
  border-radius: 1rem;
  background-color: $light-gray;
  color: $black;
  padding:0 1rem;
  margin: .2rem;
}

.skill-set-pill{
  display: inline-block;
  border-radius: 1rem;
  background-color: $light-gray;
  color: $black;
  padding:0 .5rem;
  margin: .2rem;
  font-size:.7rem;
}