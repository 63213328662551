.valid-msg {
  color: #00C900;
  position: absolute;
    top: -1.5rem;
    left: 3rem;
}

.error-msg {
  color: red;
  position: absolute;
    top: -1.5rem;
    left: 3rem;
}


.intl-tel-input {
  // display: inline-block;
  // margin-bottom: 1rem;
}

.flag-container{
  margin-bottom: 1rem;
}

.phone {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  @include breakpoint(medium){
    margin-right: 2rem;
  }

  &_type{
    // display: inline-block;
   width: auto;
   flex: 2;
   margin-left: 1rem;
  }
  select {
    // display: inline-block;
   width: auto;
   flex: 2;
   margin-left: 1rem;
  }
}

.p_delete{
  flex: 0 0 100%;
}