/** Base container **/
.kalendae {
  display: inline-block;
  zoom: 1;
  *display: inline;
  background: #fff;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  //border: solid 1px black;
  font-size: .9rem;
  font-family: $lato;
  cursor: default;
  position: relative;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.kalendae * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/** Popup Container for Kalendae.Input **/
.kalendae.k-floating {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100000;
  margin: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
}

/** Kalendae.Input's popup close button **/
.kalendae .k-btn-close {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  background: white;
  border: 2px solid #ccc;
  color: #999;
  line-height: 17px;
  text-align: center;
  font-size: 13px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
  cursor: pointer;
  text-decoration: none;
}

.kalendae .k-btn-close:after {
  content: "\2716";
}

.kalendae .k-btn-close:hover {
  color: #7EA0E2;
  background: white;
  border-color: #7EA0E2;
}

/** Month Container **/
.kalendae .k-calendar {
  display: inline-block;
  zoom: 1;
  *display: inline;
  width: 200px;
  vertical-align: top;
  margin: .2rem 0;
  //text-align: center;
}

/** Month Separator **/
.kalendae .k-separator {
  display: inline-block;
  zoom: 1;
  *display: inline;
  width: 2px;
  vertical-align: top;
  background: $light-gray;
  height: 155px;
  margin: 0px 10px;
}

/** Month Title Row **/
.kalendae .k-title {
  text-align: center;
  white-space: nowrap;
  position: relative;
  height: 1.3rem;
  background-color: #f2f5f9;
}

.kalendae .k-caption {
  font-size: 1rem;
  line-height: 18px;
}

.kalendae .k-caption-month,
.kalendae .k-caption-year {
  display: inline;
}

.kalendae .k-caption-month {
  margin-right: 0.4em;
}


/** Month and Year Buttons **/
.kalendae .k-btn-previous-month,
.kalendae .k-btn-next-month,
.kalendae .k-btn-previous-year,
.kalendae .k-btn-next-year {
  width: 16px;
  height: 23px;
  cursor: pointer;
  position: absolute;
  top: -3px;
  color: #777;
  font-size: 32px;
  line-height: 18px;
  font-weight: bold;
  font-family: arial;
  text-decoration: none;
}

.kalendae .k-btn-previous-year {
  left: 0;
}

.kalendae .k-btn-previous-month {
  left: 16px;
}

.kalendae .k-btn-next-month {
  right: 16px;
}

.kalendae .k-btn-next-year {
  right: 0;
}

.kalendae .k-btn-previous-month:after {
  content: "\2039";
}

.kalendae .k-btn-next-month:after {
  content: "\203A";
}

.kalendae .k-btn-previous-year:after {
  content: "\00AB";
}

.kalendae .k-btn-next-year:after {
  content: "\00BB";
}

.kalendae .k-btn-previous-month:hover,
.kalendae .k-btn-next-month:hover {
  color: #7EA0E2;
}

.kalendae .k-btn-previous-year:hover,
.kalendae .k-btn-next-year:hover {
  color: #6FDF81;
}

/** Remove extra buttons when calendar shows multiple months **/
.kalendae .k-first-month .k-btn-next-month,
.kalendae .k-middle-month .k-btn-next-month,
.kalendae .k-middle-month .k-btn-previous-month,
.kalendae .k-last-month .k-btn-previous-month,
.kalendae .k-first-month .k-btn-next-year,
.kalendae .k-middle-month .k-btn-next-year,
.kalendae .k-middle-month .k-btn-previous-year,
.kalendae .k-last-month .k-btn-previous-year {
  display: none;
}

/** Disable year nav option **/
.kalendae .k-title.k-disable-year-nav .k-btn-next-year,
.kalendae .k-title.k-disable-year-nav .k-btn-previous-year {
  display: none;
}

.kalendae .k-title.k-disable-year-nav .k-btn-next-month {
  right: 0;
}

.kalendae .k-title.k-disable-year-nav .k-btn-previous-month {
  left: 0;
}

/** Force specific width for month container contents **/
.kalendae .k-title,
.kalendae .k-header,
.kalendae .k-days {
  width: 200px;

  display: block;
  overflow: hidden;
}


/** Hide unusable buttons **/
.kalendae.k-disable-next-month-btn .k-btn-next-month,
.kalendae.k-disable-previous-month-btn .k-btn-previous-month,
.kalendae.k-disable-next-year-btn .k-btn-next-year,
.kalendae.k-disable-previous-year-btn .k-btn-previous-year {
  display: none;
}


/** Week columns and day cells **/
.kalendae .k-header span,
.kalendae .k-days span {
  float: left;
  margin: .2rem;
}

.kalendae .k-header span {
  text-align: center;
  font-weight: bold;
  width: 22px;
  padding: 1px 0;
  color: #666;
}

.kalendae .k-header.k-active span {
  cursor: pointer;
  border-radius: 3px;
}

.kalendae .k-days span {
  text-align: center;
  width: 22px;
  height: 1.5em;
  line-height: 1em;
  padding: 2px 3px 2px 2px;
  border: 1px solid transparent;
  //padding: .5rem;
  //border-radius: 3px;
  color: #999;
}

/** Today **/
.kalendae .k-today {
  text-decoration: underline;
}

/** Days inside of the month view **/
.kalendae .k-days span.k-in-month.k-active {
  border-color: darken(#f2f5f9, 10);
  background-color: #f2f5f9;
  color: #333;
}

/** Days outside of the month view (before the first day of the month, after the last day of the month) **/
.kalendae .k-days span.k-out-of-month {
  color: #ddd;
}

/** Selectable  **/
.kalendae .k-days span.k-active {
  cursor: pointer;
}

/** Selected day, when outside the selectable area **/
.kalendae .k-days span.k-selected {
  border-color: #1072A5;
  color: #1072A5;
}

/** Selected day, when inside the selectable area **/
.kalendae .k-days span.k-selected.k-active,
.kalendae .k-header.k-active span.k-selected {
  background: #7EA0E2;
  color: white;
}

/** Days between the start and end points on a range, outside of the selectable area **/
.kalendae .k-days span.k-range {
  background: none;
  border-color: #6DD4FE;
}

/** Days between the start and end points on a range, inside of the selectable area **/
.kalendae .k-days span.k-range.k-in-month {
  background: #C4D4F1;
  border-color: #19AEFE;
  color: #333;
}

/** Selectable day, hovered **/
.kalendae .k-days span.k-active:hover,
.kalendae .k-days span.k-active.k-day-hover-active {
  border-color: #666;
}

/** Selectable week, hovered **/
.kalendae .k-week:hover span.k-active {
  border-color: #666;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

/*-------------------------------------IE8 ONLY CODE BELOW THIS LINE--------------------------------------------*/

.kalendae.ie8.k-floating {
  border: 1px solid #ccc;
}

.kalendae.ie8 .k-btn-close {
  width: 20px;
  height: 20px;
  border: none;
  background: url('../../images/kalendae/close.png') no-repeat top left;
}

.kalendae.ie8 .k-btn-close:after {
  display: none;
}

.kalendae.ie8 .k-btn-previous-month,
.kalendae.ie8 .k-btn-next-month,
.kalendae.ie8 .k-btn-previous-year,
.kalendae.ie8 .k-btn-next-year {
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: #777 url('../../images/kalendae/arrows.png') no-repeat center left;
  position: absolute;
  top: 0;
}

.kalendae.ie8 .k-btn-next-month,
.kalendae.ie8 .k-btn-next-year {
  background-position: center right;
}

.kalendae.ie8 .k-btn-previous-month:hover,
.kalendae.ie8 .k-btn-next-month:hover {
  background-color: #7EA0E2;
}

.kalendae.ie8 .k-btn-previous-year,
.kalendae.ie8 .k-btn-next-year {
  background-color: #333;
}

.kalendae.ie8 .k-btn-previous-year:hover,
.kalendae.ie8 .k-btn-next-year:hover {
  background-color: #6FDF81;
}

.kalendae.ie8 .k-btn-previous-month:after,
.kalendae.ie8 .k-btn-next-month:after,
.kalendae.ie8 .k-btn-previous-year:after,
.kalendae.ie8 .k-btn-next-year:after {
  display: none;
}

