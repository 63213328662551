.notification-box {
  z-index: 150;
  display: none;
  position: absolute;
  height: auto;
  width: 100%;
  background-color: white;
  top: 50px;
  color: $dark-gray;
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
  border-top-left-radius: .5rem;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, .3);

  @include breakpoint(medium) {
    width: 350px;
    right: 150px;
  }

  &_title {
    text-align: center;

    i {
      margin-right: .5rem;
    }

    padding: 10px;
    border-bottom: 1px solid $light-gray;
    position: relative;

    a {
      color: $dark-gray;
      position: absolute;
      right: 5px;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    @include breakpoint(medium){
      max-height: 30rem;
      overflow-y: auto;
    }
  }

  li {
    padding: 15px 20px;

    a {
      display: flex;
      color: $dark-gray;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $light-gray;
    }

    &:hover {
      background-color: $color-bg-blue;
    }

    &:last-child {
      border-bottom-left-radius: .5rem;
      border-bottom-right-radius: .5rem;
    }
  }

  &_icon {
    flex-shrink: 0;
    font-size: 1.5rem;
    height: 35px;
    width: 35px;
    border-radius: 2rem;
    line-height: 0;
    padding-top: 4px;
    text-align: center;
    border: solid 1px $light-gray;
    margin-right: 1rem;
    background-color: $white;
  }

  &_text {
    //display: flex;
    align-items: center;
    font-size: .9rem;

    &_bottom {
      font-size: .7rem;
    }
  }

}

.close-noti {
  color: $dark-gray;
  position: absolute;
  right: 5px;
  top: 10px;

  &:hover {
    cursor: pointer;
  }
}

.mark-read {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: $medium-gray;

  &:hover {
    cursor: pointer;
    color: darkred;
  }
}

.live_notify_list {
  li {
    position: relative;
  }
}