.client-thumbnail {
  text-align: center;
  margin: 1rem 0;

  img {
    height: 100px;
    width: 100px;
    background-color: $light-gray;
    border-radius: 50%;
  }
}

.client-name {
  text-align: center;
  color: $dark-gray;


}

.client-code {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: .5rem;
}


.client-details {
  list-style: none;;
  margin: 0;
  font-size: .9rem;
}

.info-block {

  //button{
  //  padding-right: 3rem;
  //
  //  i{
  //    margin-right: 1rem;
  //  }
  //}
  //// display: block;
  //margin-bottom: .5rem;
  &_heading {
    font-size: .8rem;
    color: $medium-gray;
  }

  &_content {
    font-size: .9rem;
    margin-bottom: .8rem;

    //&_contract{
    //  display: flex;
    //
    //  width: 150px;
    //  height: 50px;
    //  border: solid 1px $light-gray;
    //  i{
    //    background-color: $color-blue;
    //    font-size: 2rem;
    //    color: white;
    //    padding:6px 5px 0 5px;
    //
    //  }
    //  a{
    //    padding: .2rem .4rem;
    //    text-align: center;
    //
    //  }
  }

  //img{
  //  height: 50px;
  //  max-width: 100%;
  //  min-width: 50px;
  //  border: solid 1px $light-gray;
  //}
  //}
}

// parent must have position:relative
.pink-line {
  position: relative;
  // margin-top: 2rem;
}

.pink-line::after {
  content: '';
  position: absolute;
  height: 4px;
  width: 30px;
  background-color: $color-pink;
  top: 0;
  left: 0;
}

.data-block {
  &_headings {
    margin-top: .5rem;
    padding-top: 5px;
    width: 100%;
    margin-bottom: 1rem;
    margin-left: 0;

    h4 {

      color: $color-purple;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 1rem;
      margin: 0;
    }

    h5 {

      font-size: .8rem;
      font-weight: bold;
      color: $dark-gray;
    }
  }

}

.elements {
  position: relative;
  padding-top: 5px;
  margin-bottom: 2rem;
}

.const-act {
  &_title {
    color: $dark-gray;
    font-size: .9rem;
  }
}

.business-logo {
  max-height: 200px;
  //text-align: center;
  margin-bottom: 1rem;

  img {
    //border-radius: 50%;
    max-height: 200px;
    max-width: 100%;
  }
}

.info-list {

  list-style: none;
  margin: 0;

  li {
    small {
      color: $medium-gray;
    }
  }
}

.profile {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.profile-pic {
  margin-right: 5px;
  flex-shrink: 0;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: $light-gray;
}

.rep-info {
  .fa {
    color: $medium-gray;
  }
}

#contract-name {
  font-size: .9rem;
  color: $medium-gray;
  margin-bottom: .5rem;
}