.position-left ~ .off-canvas-content.sidebar-collapsed{
  // transition: all .2s;
  @include breakpoint(medium){
    margin-left: 55px;
}
}

.position-left.sidebar-collapsed{
  // transition: all .2s;
  @include breakpoint(medium){
    width: 55px;
  }
}
.position-left ~ .off-canvas-content.sidebar-expanded{
  // transition: all .2s;
  @include breakpoint(medium){
    margin-left: 200px;
}
}

.position-left.sidebar-expanded{
  // transition: all .2s;
  @include breakpoint(medium){
    width: 200px;
  }
}

.new-menu.sidebar-collapsed{
  left: 55px;
}