.messages {
  z-index: 150;
  background-color: $white;
  list-style: none;
  margin: 0;
  position: absolute;
  //border-left: solid 3px $color-pink;
  //border-right: solid 1px $light-gray;
  //border-bottom: solid 1px $light-gray;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, .3);
  padding: .3rem .4rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  //text-align: center;
  @include breakpoint(medium) {
    left: 50%;
    transform: translateX(-50%);
    //min-width: 500px
    padding: .3rem 1rem;
  }

  .fa{
    margin-right: .5rem;
  }
  .success {
    .fa{
      color: $color-success;
    }
  }
  .debug{
    .fa{
      color: $color-purple;
    }
  }
  .info{
    .fa{
      color: $color-blue;
    }
  }
  .warning{
    .fa{
      columns: $color-orange;
    }
  }
  .error{
    .fa{
      color: $color-pink;
    }
  }

}