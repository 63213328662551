.top-bar{
  height: 50px;
  background-color: $color-purple;
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0,0,0,.3);
  color: $white;
  // padding: 0 .5rem;
  @include breakpoint(medium){
    // height: 80px;
    
    border-bottom: 5px solid $color-pink;
    // padding: .5rem;
    // justify-content: flex-end;
  }
}

.user-elements{
  // background-color: $dark-gray;
  padding-right: .5rem;
  display: flex;
  align-items: center;
}

.notification-icon{
  margin-right:1.5rem;
  position: relative;
  padding: 2px 7px;
  border-radius: 1.5rem;
  background-color: transparent;
  transition: all .1s;
  &_counter{
    position: absolute;
    background-color: red;
    height: 15px;
    width: 15px;
    font-size: .6rem;
    border-radius: 50%;
    text-align: center;
    left: 50%;
    top: -5px;
    // display: none;
  }

  &:focus,&:active,&:hover{
    transition: all .1s;
    background-color: darken($color-purple, 10);
    cursor: pointer;
  }
}

.user-profile-btn{
  display: flex;
  align-items: center;
  padding: 0 .5rem;
  height: 100%;
  &_img{
    height: 30px;
    width: 30px;
    overflow: hidden;
    border-radius: 50%;
    border: solid 2px $white;
    margin: 0 .3rem;
  }

  span{
    display: none;
    @include breakpoint(medium){
      display: block;
    }
  }

  &:focus,&:active,&:hover{
    transition: all .1s;
    background-color: darken($color-purple, 10);
    cursor: pointer;
  }


}

.user-menu{
  z-index: 150;;
  display: none;
  position: absolute;
  height: auto;
  width: auto;
  background-color: $white;
  top: 50px;
  right: 0;  
  color: $dark-gray;
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
  box-shadow: -2px 3px 4px rgba(0,0,0,.3);

  ul{
    list-style: none;
    margin: 0;
  }

  li{
    padding: .4rem 2.5rem .4rem 1.5rem;

    a{
      color: $dark-gray
    }

    &:hover,&:focus,&:active{
      background-color: $light-gray;
    }

    i{
      margin-right: .5rem;
    }

    &:first-child{
      font-size: .9rem;
      text-transform: uppercase;
      text-align: center;
      border-bottom: 1px solid $light-gray;
    }

    &:last-child{
      border-top: solid 1px $light-gray;
      border-bottom-left-radius: .5rem;
      border-bottom-right-radius: .5rem;

      i{
        color: red;
      }

    }
  }

}



.sidebar-toggle{
  // height: 30px;
  // width: 30px;
  // position: absolute;
  // left: .5rem;
  // top: .6rem;
  padding: 0 .5rem;
  display: flex;
  align-items: center;

  img{
    height: 30px;
    width: 30px;
  }

  @include breakpoint(medium){
    top: 1.5rem;
  }

  @include breakpoint(large){
    // display: none;

  }
}

.page-title{
  text-align: center;
  color: $white;
  padding-top: .2rem;
  h1{
    font-size: 1rem;
    // margin: 0;
    margin-bottom: .2rem;
    @include breakpoint(medium){
      font-size: 1.1rem;
    }
  }
  h3{
    font-size: .8rem;
    margin: 0;    
  }
}

.page-icon{
  display: none;
  @include breakpoint(medium){
    position: absolute;
    top: 50%;
    left: 4rem;
    height: 40px;
    width: 40px;
    transform: translateY(-50%);
    display: block;
  }

  img{
    max-width: 100%;
    max-height: 100%;
  }
}

.back-nav{
  position: absolute;
  top: 50%;
  right:1rem;
  transform: translateY(-50%);
  @include breakpoint(medium){
    left:2rem;
  }
  
  a{
    font-size: 1.5rem;
    color: $color-pink;
  }

  img{
    display: none;
    @include breakpoint(medium){
      display: inline-block;
      background-color: $light-gray;
      height: 45px;
      width: 45px;
      border-radius: 50%;
      border: solid 2px $color-pink;
      overflow: hidden;
      margin-top: -10px;
      margin-left: .7rem;
    }
  }

}