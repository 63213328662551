.tabs-title {

  @include breakpoint(large) {
    min-width: 150px;
  }

  a {
    color: $medium-gray;
    @include breakpoint(medium) {
      font-size: .7rem;
    }
    border-left: solid 1px $light-gray;
    border-right: solid 1px $light-gray;
    border-top: solid 1px $light-gray;

    // border-bottom: solid 1px $light-gray;
    // border-top-left-radius: 5px;
    // border-top-right-radius: 5px;
  }
}

.payment-tracking-tabs {
  @include tabs-container;

  @include breakpoint(large) {
    @include tabs-container-vertical;

    .tabs-title {
      a {
        border: none;
        border-top: solid 1px $light-gray;
      }
    }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {

      // border-left: solid 1px $color-purple;
      // border-right: solid 1px $color-purple;
      border-top: solid 1px $color-purple;
      border-bottom: solid 1px $color-purple;
      border-left: none;
      border-right: none;
    }
  }

  @include breakpoint(medium down) {

    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      color: black;
      border-top: solid 1px $color-purple;
      border-left: solid 1px $color-purple;
      border-right: solid 1px $color-purple;
    }
  }
}


//.tabs-title > a:focus, .tabs-title > a[aria-selected='true']{
//  color: black;
//  border-top: solid 1px $color-purple;
//  border-left: solid 1px $color-purple;
//  border-right: solid 1px $color-purple;
//
//
//}

// .vertical-table{

.tabs-panel {
  min-height: 400px;

}


// }


.tabs-content {
  border: solid 1px $light-gray;

  @include breakpoint(large) {
    border: 1px solid $light-gray;
    border-right: none;
  }
}