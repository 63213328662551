[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
{
  border: none;
  border-bottom: 1px solid $black;
  box-sizing: border-box; 
  
}

textarea {
  border: none;
  border-bottom: 1px solid $black;
  box-sizing: border-box; 
  

}

[type='text']:focus,
[type='password']:focus,
[type='date']:focus,
[type='datetime']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='week']:focus,
[type='email']:focus,
[type='number']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='url']:focus,
[type='color']:focus,
textarea:focus {
  // box-shadow: none;
  border-bottom: 2px solid $color-purple;
  background-color: rgba(99, 174, 243, 0.05);
}

select {
  border: none;
  border-bottom: 1px solid $black;

  &:focus {
    // box-shadow: none;
    border-bottom: 2px solid $color-purple;
    background-color: rgba(99, 174, 243, 0.05);
  }
}


.inline-inputs{
display: inline-block;

  [type='number']{
    width: 50px;
    
    display: inline-block;
  }
  select{
    margin-left: 10px;
    display: inline-block;
    width: 100px;
    
  }
}

label{
  @include breakpoint(medium){

    margin-right: 2rem;
  }
}

.weekday-selector{
  display: inline-block;
  margin: 0 1rem;
}

select:disabled,
input:disabled{
  background-color: $white;
  color: $light-gray;
  border-bottom: $light-gray 1px solid;
}