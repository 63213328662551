.new-menu{
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $white;
  width: 100%;
  height: 100%;
  z-index: 20;
  @include breakpoint(medium){
    //display: list-item;
    //max-height: 310px;
    //height: fit-content;
    height: auto;
    width: 310px;
    left: 200px;
    top: 50px;
    border-radius: 0 .5rem .5rem .5rem;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

  }

  .close-button{
    @include breakpoint(medium){
      display: none;
    }
  }

  ul{
    list-style: none;
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border: solid 1px $light-gray;
    border-radius: .5rem;
    width: 80%;
    padding: 0 1rem;
    @include breakpoint(medium){
      position: relative;
      padding: 0;
      width: auto;
      left: 0;
      top: 0;
      transform: translate(0);
      //width: 100%;
      border: none;
    }
    
  }

  li:not(:last-child) {
    border-bottom: solid 1px $light-gray;
  }

  li{
    @include breakpoint(medium){
      margin: 0 .5rem;
    }
  }


  a{

    display: flex;
    align-items: center;
    color: $black;
    transition: all .2s;
    background: $white;

    &:focus,&:active,&:hover{
      transition: all .2s;
      // background: linear-gradient(to right, rgba(211,211,211,0) 0%,rgba(211,211,211,1) 25%,rgba(211,211,211,1) 50%,rgba(211,211,211,1) 75%,rgba(211,211,211,0) 100%); 
      background: $light-gray;
    }
  }

  &_icon{
    margin: 1rem;
    background-color: $color-purple;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    text-align: center;
    

    img{
      height: 80%;
      width: 80%;
      margin-top: 5px;
    }
  }
}

.new-menu_text{
  padding:.2rem .5rem;
//  background-color: $color-orange;
//  text-align: center;
//  margin: .5rem 0;
}